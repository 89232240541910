<template>
    <div
        class="full-height flex-column"
    >
        <div class="">
        </div>
        <div class="mt-10 full-height flex-column">
            <div class="justify-space-between">
                <div class="flex-row">
                    <p>{{ item.type }} {{ item.name }}</p>
                    <span class="ml-10">정산예정금액</span><span class="ml-10" style="color: #EC758B">{{ totalAmount }}</span>
                </div>

                <div>
                    <button
                        class="box mr-10 pa-4-10 size-px-12"
                        @click="toExcel"
                    ><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
                </div>
            </div>
            <Search
                class="mt-10 box-ddd"
                :search="search"
                :option="search_option"
                :search_sub_none="true"
                @click="getSearch(1)"
                @reset="reset"
            >
            </Search>
            <table
                v-if="items.length > 0"
                class="table top-line-identify mt-10"
                >
                <colgroup>
                    <col width="50px" />
                    <col width="auto" />
                    <col width="auto" />
                    <col width="auto" />
                    <col width="auto" />

                    <col width="auto" />
                    <col width="auto" />
                    <col width="auto" />
                    <col width="auto" />
                    <col width="auto" />

                    <col width="auto" />
                    <col width="auto" />
                    <col width="auto" />
                    <col width="auto" />
                    <col width="auto" />

                    <col width="auto" />
                    <col width="auto" />
                </colgroup>
                <thead>
                <tr>
                    <th>NO</th>
                    <th>총판 상호</th>
                    <th>대리점 상호</th>
                    <th>리셀러 상호</th>
                    <th>가맹점 상호</th>

                    <th>승인번호</th>
                    <th>결제일</th>
                    <th>이체일</th>
                    <th>승인금액</th>
                    <th>이체금액</th>

                    <th>총 수수료율</th>
                    <th>총 수수료액</th>
                    <th>본사 수수료</th>
                    <th>지사 수수료</th>
                    <th>총판 수수료</th>

                    <th>대리점 수수료</th>
                    <th>리셀러 수수료</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="'item_' + index"
                >
                    <td>{{ item.idx }}</td>
                    <td class="text-left">
                        <span v-if="item.distributorName">{{ item.distributorName}}</span>
                        <span v-else>-</span>
                    </td>
                    <td class="text-left">
                        <span v-if="item.agencyName">{{ item.agencyName}}</span>
                        <span v-else>-</span>
                    </td>
                    <td class="text-left">
                        <span v-if="item.resellerName">{{ item.resellerName}}</span>
                        <span v-else>-</span>
                    </td>
                    <td class="text-left">
                        <span v-if="item.shopName">{{ item.shopName}}</span>
                        <span v-else>-</span>
                    </td>

                    <td style="word-break: break-all">{{ item.approvalNum }}</td>
                    <td>{{ item.approvalDate }}</td>
                    <td>{{ item.transferDate }}</td>
                    <td class="text-right">{{ item.approvalAmount }}</td>
                    <td class="text-right">{{ item.transferAmount }}</td>

                    <td>{{ item.fee }}</td>
                    <td class="text-right">{{ item.feeAmount }}</td>
                    <td class="text-right">{{ item.companyFeeAmount }}</td>
                    <td class="text-right">{{ item.branchFeeAmount }}</td>
                    <td class="text-right">{{ item.distributorFeeAmount }}</td>

                    <td class="text-right">{{ item.agencyFeeAmount }}</td>
                    <td class="text-right">{{ item.resellerFeeAmount }}</td>
                </tr>
                </tbody>
            </table>

            <div
                v-else
                class="full-height flex-column justify-center top-line-identify mt-10 pa-10"
            >No Data</div>

            <pagination
                class="mt-auto"
                :options="search"

                @click="getSearch"
            ></pagination>
        </div>

        <Excel
            v-if="is_excel"
            :excel_data="excel_data"
            :date="date"

            @finish="is_excel = !is_excel"
        ></Excel>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { codes } from '@/resources/config/codes'
import { Axios } from '@/resources/axios/axios'
import {date} from "@/resources/date/date";
import Excel from "@/components/Excel";
import Search from "@/view/Layout/Search.vue";

export default {
    name: 'SettlementDetail'
    ,components: {Search, Excel, Pagination}
    ,props: ['item', 'user', 'year', 'month']
    ,data: function() {
        return {
            codes: codes
            , Axios: Axios
            , date: date
            , search: {
                page: 1
                , size: 10
                , idx: this.item.idx
                , startDate: ''
                , endDate: ''
                , keyword: ''
                , total_count: 0
                , sDate: ''
                , eDate: ''
            }
            ,search_option: {
                sDate: true
                ,eDate: true
                ,is_search_date_type: true
            }
            , items: []
            , is_excel: false
            , excel_data: {
                name: `${this.item.name} 정산 예정 상세`
                , header: [
                    {key: 0, name: '총판상호', column: 'distributorName'}
                    , {key: 0, name: '대리점 상호', column: 'agencyName'}
                    , {key: 0, name: '리셀러 상호', column: 'resellerName'}
                    , {key: 0, name: '가맹점 상호', column: 'shopName'}
                    , {key: 0, name: '승인번호', column: 'approvalNum'}
                    , {key: 0, name: '결제일', column: 'approvalDate'}
                    , {key: 0, name: '이체일', column: 'transferDate'}
                    , {key: 0, name: '승인금액', column: 'approvalAmount'}
                    , {key: 0, name: '이체금액', column: 'transferAmount'}
                    , {key: 0, name: '총 수수료율', column: 'fee'}
                    , {key: 0, name: '총 수수료액', column: 'feeAmount'}
                    , {key: 0, name: '본사 수수료', column: 'companyFeeAmount'}
                    , {key: 0, name: '지사 수수료', column: 'branchFeeAmount'}
                    , {key: 0, name: '총판 수수료', column: 'distributorFeeAmount'}
                    , {key: 0, name: '대리점 수수료', column: 'agencyFeeAmount'}
                    , {key: 0, name: '리셀러 수수료', column: 'resellerFeeAmount'}
                ]
                , content: null
            }
            , totalAmount: 0
        }
    }
    ,methods: {

        getData: async function(){
            try{
                this.$emit('onLoading')
                const result = await this.Axios({
                    method: 'get'
                    ,url: 'quick/scheduled_settlement/detail'
                    ,data: this.search
                })

                if(result.success){
                    console.log(result.data)
                    this.items = result.data.tableData.data
                    this.search.total_count = result.data.tableData.totalCount
                    this.totalAmount = result.data.statistics.totalAmount
                }else{
                    this.$emit('setNotify', { type: 'error', message: result.message})
                }
            }catch(e){
                this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
                console.log(e)
            }finally {
                this.$emit('offLoading')
            }
        }
        ,setDate: function () {
            console.log(this.month)
            let start = new Date(this.year, this.month, 1)
            let last = new Date(this.year, this.month, 0)
            let startDay = start.getDate()
            let lastDay = last.getDate()
            this.search.startDate = this.year + '.' + (Number(this.month) > 9 ? '' : '0') + (Number(this.month)) + '.' + (startDay > 9 ? '' : '0') + startDay
            this.search.endDate = this.year + '.' + (Number(this.month) > 9 ? '' : '0') + (Number(this.month)) + '.' + lastDay
            this.search.sDate = this.search.startDate.replaceAll('.', '-')
            this.search.eDate = this.search.endDate.replaceAll('.', '-')

            this.getData()
        }
        ,toExcel: async function(){

            try{
                this.$emit('onLoading')
                const result = await this.Axios({
                    method: 'get'
                    ,url: 'quick/scheduled_settlement/detail/excel/'
                    ,data: {
                        idx: 3
                        ,startDate: this.search.startDate
                        ,endDate: this.search.endDate
                        ,keyword: this.search.keyword
                    }
                })

                if(result.success){
                    this.excel_data.content = result.data
                    this.is_excel = true
                }else{
                    this.$emit('setNotify', { type: 'error', message: result.message})
                }
            }catch(e){
                this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
                console.log(e)
            }finally {
                this.$emit('offLoading')
            }
        }
        ,reset: function(){
            this.search = {
                page: 1
                , size: 10
                , idx: this.item.idx
                , startDate: ''
                , endDate: ''
                , keyword: ''
                , total_count: 0
            }
            this.setDate()
        }
        ,getSearch: function(page){
            if(page){
                this.search.page = page
            }
            if(this.search.sDate && this.search.eDate) {
                this.search.startDate = this.search.sDate.replaceAll('-', '.')
                this.search.endDate = this.search.eDate.replaceAll('-', '.')
            }
            this.getData()
        }
    }
    ,created() {
        this.setDate()
    }
}
</script>