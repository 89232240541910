<template>
    <div
        class="full-height"
    >
        <h6>{{ program.name }}</h6>

        <Search
            class="mt-10 box"
            :search="search"
            :option="search_option"
            :search_sub_none="true"
            :codes="codes"

            @reset="reset"
            @click="getSearch"
        >
            <select
                slot="add"
                class="pa-5-10 mr-10"

                v-model="search.type"
                @change="getSearch(1)"
            >
                <option value="">영업단 선택</option>
                <template
                    v-for="(agency, index) in codes.agency_type"
                >
                    <option
                        v-if="index > 0 && index <= codes.agency_type.length - 2 && index > user.roleCode"
                        :key="'agency_' + index"
                        :value="agency.code"
                    >{{ agency.name }}</option>
                </template>
            </select>
        </Search>

        <div class="mt-10 pa-10 bg-white full-height flex-column overflow-y-auto">

            <div class="justify-space-between">
                <div class="flex-row align-center">
                    <v-icon
                        @click="prev()"
                    >mdi mdi-chevron-left</v-icon>

                    <span class="vertical-middle prl-10">{{ search.year }}년 {{ Number(search.month) }}월</span>

                    <v-icon
                        @click="next()"
                    >mdi mdi-chevron-right</v-icon>
                    <div>
                        <span>정산 예정 금액</span>
                        <span style="color: #EC758B"> {{ totalAmount }}</span>
                    </div>
                </div>
                <div>
                    <button
                        class="box mr-10 pa-4-10 size-px-12"
                        @click="toExcel"
                    ><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
                    <select
                        class="pa-5-10 mr-10 size-px-12"
                        v-model="search.size"
                        @change="getSearch(1)"
                    >
                        <option
                            v-for="cnt in codes.list_cnt"
                            :key="'cnt_' + cnt"
                            :value="cnt"
                        >{{ cnt }} 건씩 보기</option>
                    </select>
                </div>
            </div>
            <div v-if="items.length > 0">
              <table
                  class="mt-10 table table-even top-line-identify"
              >
                  <colgroup>
                      <col width="50px" />
                      <col width="80px" />
                      <col width="160px" />
                      <col width="auto" />
                      <col width="auto" />

                      <col width="auto" />
                      <col width="auto" />
                      <col width="auto" />
                      <col width="auto" />
                      <col width="auto" />

                      <col width="auto" />
                      <col width="auto" />
                      <col width="auto" />
                      <col width="100px" />
                  </colgroup>
                  <thead>
                  <tr>
                      <th>NO</th>
                      <th>구분</th>
                      <th>상호</th>
                      <th>승인건수</th>
                      <th>총 승인금액</th>

                      <th>총 이체금액</th>
                      <th>총 수수료율</th>
                      <th>총 수수료액</th>
                      <th>본사 수수료</th>
                      <th>지사 수수료</th>

                      <th>총판 수수료</th>
                      <th>대리점 수수료</th>
                      <th>리셀러 수수료</th>
                      <th>상세</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(item, index) in items"
                      :key="'list_' + index"
                  >
                      <td>{{ item.idx }}</td>
                      <td>{{ item.type }}</td>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-right">{{ item.approvalCount }}</td>
                      <td class="text-right">{{ item.approvalAmount  }}</td>

                      <td class="text-right">{{  item.transferAmount }}</td>
                      <td>{{  item.fee }}</td>
                      <td class="text-right">{{  item.feeAmount }}</td>
                      <td class="text-right">{{  item.companyFeeAmount }}</td>
                      <td class="text-right">{{  item.branchFeeAmount }}</td>

                      <td class="text-right">{{  item.distributorFeeAmount }}</td>
                      <td class="text-right">{{  item.agencyFeeAmount }}</td>
                      <td class="text-right">{{  item.resellerFeeAmount }}</td>
                      <td>
                          <button
                              class="bg-identify pa-5-10 size-px-12"
                              @click="toDetail(item)"
                          >상세보기</button>
                      </td>
                  </tr>
                  </tbody>
              </table>
              <p class="text-right mt-10">※ 수수료는 부가세 포함</p>
            </div>

            <div
                v-else
                class="full-height flex-column justify-center top-line-identify mt-10"
            >
                <div class="text-center">
                    <v-icon
                        class="size-px-48 color-icon"
                    >mdi mdi-cloud-off-outline</v-icon>
                    <br/>
                    <br/>
                    <div class="font-weight-bold size-px-24">No Data</div>
                </div>
            </div>

            <Pagination
                :program="program"
                :align="'center'"
                :options="search"

                class="mt-auto"
                @click="getSearch"
            ></Pagination>
        </div>

        <Excel
            v-if="is_excel"
            :excel_data="excel_data"
            :date="date"

            @finish="is_excel = !is_excel"
        ></Excel>

        <Modal
            :is_modal="is_modal"
            :option="modal_option"
            width="95%"
            height="550px"

            @close="close"
        >
            <QuickScheduledDetail
                slot="modal-content"
                :item="item_sub"
                :year="search.year"
                :month="search.month"
                :Axios="Axios"
                :codes="codes"
                :user="user"

            ></QuickScheduledDetail>
        </Modal>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";
import QuickScheduledDetail from "@/view/Quick/QuickScheduledDetail.vue";
import moment from "moment";
export default {
    name: 'QuickScheduledSettlement',
    components: {QuickScheduledDetail, Modal, Excel, Search, Pagination},
    props: ['user', 'codes', 'date', 'Axios', 'query']
    ,data: function(){
        return {
            program: {
                name: '배달비 정산 예정'
                ,top: true
                ,title: true
                ,bottom: false
            }
            ,search: {
                page: 1
                ,size: 10
                ,month: ''
                ,year: ''
                ,type:''
                ,keyword: ''
                ,total_count: 0
                ,search_type: ''
            }
            ,search_option: {
                search_type: [
                    { name: '영업점', column: 'name'}
                ]
            }
            ,add_agency_type: this.codes.agency_type
            ,items: []
            ,is_excel: false
            ,excel_data: {
                name: '영업점 목록'
                ,header: [
                    { key: 0, name: '구분', column: 'type'}
                    ,{ key: 0, name: '상호', column: 'name'}
                    ,{ key: 0, name: '승인건수', column: 'approvalCount'}
                    ,{ key: 0, name: '총 승인금액', column: 'approvalAmount'}
                    ,{ key: 0, name: '총 이체금액', column: 'transferAmount'}
                    ,{ key: 0, name: '총 수수료율', column: 'fee'}
                    ,{ key: 0, name: '총 수수료액', column: 'feeAmount'}
                    ,{ key: 0, name: '본사 수수료', column: 'companyFeeAmount'}
                    ,{ key: 0, name: '지사 수수료', column: 'branchFeeAmount'}
                    ,{ key: 0, name: '총판 수수료', column: 'distributorFeeAmount'}
                    ,{ key: 0, name: '대리점 수수료', column: 'agencyFeeAmount'}
                    ,{ key: 0, name: '리셀러 수수료', column: 'resellerFeeAmount'}
                ]
                ,content: null
            }
            ,item_sub: {

            }
            ,is_modal: false
            ,modal_option: {
                title: '정산 예정 상세'
                ,top: true
                ,bottom: false
            }
            ,total_cnt: 0
            ,totalAmount: 0
        }
    }
    ,methods: {
        getData: async function(){
            try{
                this.$emit('onLoading')
                const result = await this.Axios({
                    method: 'get'
                    ,url: 'quick/scheduled_settlement'
                    ,data: this.search
                })

                if(result.success){
                  console.log(result.data)
                    this.items = result.data.tableData.data
                    this.totalAmount = result.data.statistics.totalAmount
                    this.search.total_count = result.data.tableData.totalCount
                    localStorage.setItem('query', JSON.stringify(this.search))
                }else{
                    this.$emit('setNotify', { type: 'error', message: result.message})
                }
            }catch(e){
                this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
                console.log(e)
            }finally {
                this.$emit('offLoading')
            }
        }
        ,setDate(){
            let date = new Date()
            let year = date.getFullYear()
            let month = date.getMonth() + 1

            this.search.year = year
            this.search.month = month
        }
        ,toDetail: function(item){
            this.item_sub = item
            this.is_modal = true
            console.log(this.item_sub)
        }
        ,getSearch: function(page){

            if(page){
                this.search.page = page
            }

            this.getData()
        }

        ,toExcel: async function(){

            try{
                this.$emit('onLoading')
                const result = await this.Axios({
                    method: 'get'
                    ,url: 'quick/scheduled_settlement/excel'
                    ,data: this.search
                })

                if(result.success){
                    this.excel_data.content = result.data
                    console.log(this.excel_data.content)
                    this.is_excel = true
                }else{
                    this.$emit('setNotify', { type: 'error', message: result.message})
                }
            }catch(e){
                this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
                console.log(e)
            }finally {
                this.$emit('offLoading')
            }
        }
        ,close: function(){
            this.is_modal = false
        }
        ,reset: function(){
            this.search = {
                page: 1
                ,size: 10
                ,month: ''
                ,year: ''
                ,type:''
                ,keyword: ''
                ,total_count: 0
            }
            this.getData()
        }
        ,prev: function(){
            let dates = new Date(this.search.year+'-'+this.search.month)
            let setDate = moment(dates).add(-1, 'months').calendar()
            this.search.year = setDate.slice(6)
            this.search.month = setDate.slice(0, 2)

            this.getSearch()
        }
        ,next: function(){
            let dates = new Date(this.search.year+'-'+this.search.month)
            let setDate = moment(dates).add(1, 'months').calendar()
            this.search.year = setDate.slice(6)
            this.search.month = setDate.slice(0, 2)

            this.getSearch()
        }
    }
    ,created() {
        this.$emit('onLoad', this.program)
        this.setDate()
        this.getData()
    }
}
</script>